var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TypeModal" },
    [
      _vm.typeVisible
        ? _c(
            "Modal",
            {
              attrs: {
                closable: true,
                visible: _vm.typeVisible,
                "mask-closable": false,
                title: (_vm.formType === "add" ? "新增" : "编辑") + "假期类型",
                size: "normal",
              },
              on: {
                ok: _vm.addVacation,
                cancel: function ($event) {
                  _vm.typeVisible = false
                },
              },
            },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "假期类型", prop: "vacationTypeName" } },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入假期类型名称",
                          disabled: _vm.formType === "add" ? false : true,
                        },
                        model: {
                          value: _vm.form.vacationTypeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "vacationTypeName", $$v)
                          },
                          expression: "form.vacationTypeName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "假期描述", prop: "vacationDesc" } },
                    [
                      _c("a-textarea", {
                        attrs: {
                          placeholder: "请输入假期描述",
                          "auto-size": { minRows: 3, maxRows: 5 },
                          "max-length": 500,
                        },
                        model: {
                          value: _vm.form.vacationDesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "vacationDesc", $$v)
                          },
                          expression: "form.vacationDesc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "默认单位", prop: "vacationTypeUnit" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "150px" },
                          model: {
                            value: _vm.form.vacationTypeUnit,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "vacationTypeUnit", $$v)
                            },
                            expression: "form.vacationTypeUnit",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "D" } }, [
                            _vm._v("天"),
                          ]),
                          _c("a-select-option", { attrs: { value: "H" } }, [
                            _vm._v("小时"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "假期颜色", prop: "textColor" } },
                    [
                      _c("CbColorPicker", {
                        model: {
                          value: _vm.form.textColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "textColor", $$v)
                          },
                          expression: "form.textColor",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }